<template>
  <nav class="footer-container">
    <div class="footer-wraper">
      <div class="footer-logo">
        <img src="../assets/footer-logo.png" class="footer-logo-img" alt="" />
        <div class="footer-logo-dec">
          <div class="footer-logo-title">山海图科技</div>
          <div class="footer-logo-subTitle">Shan Hai Map</div>
        </div>
      </div>
      <div class="footer-connect">
        <h4>公司介绍</h4>
        <p>
          北京山海图科技有限公司是一家专门帮助中国企业出海发展提供本地化服务的咨询公司。我们的服务主要包括：调研选址，工商注册，资质证照，财税法务，产品准入等。
        </p>
        <p>为目标国家引进中国的资本、技术、人才实现共同发展，互利共赢。</p>
      </div>
      <div class="footer-message">
        <div class="footer-connect">
          <h4>联系我们</h4>
          <p><span>电话</span>15686296390 (罗经理)</p>
          <p><span>电话</span>+6281292892528</p>
          <p><span>微信</span>luo85526285</p>
          <p><span>邮箱</span>zhangping@shanhaimap.com</p>
          <p>
            <span>北京公司</span
            ><span class="addr">北京市海淀区西四环北路158号1幢3层3-65</span>
          </p>
          <p>
            <span>西安公司</span
            ><span class="addr"
              >陕西省西安市高新区科技路林凯国际大厦15层1501-01-03室</span
            >
          </p>
        </div>
        <div class="footer-meta">
          <h4>官方微信小程序</h4>
          <img src="../assets/erCode.jpg" class="footer-ercode-img" alt="" />
        </div>
      </div>
    </div>
    <div class="footer-copyright">
      Copyright © www.shanhaimap.com 山海图 京ICP备16015541号-1
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Header',
  components: {
  }
}
</script>

<style scoped lang="less">
@import url("@/variable.less");
.footer-container {
  color: #e0e2e8;
  height: @footer-height;
  background-color: #041747;
  padding: 30px 0 15px 0;
}
.footer-wraper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: @base-width;
  margin: auto;
}
.footer-copyright {
  width: @base-width;
  width: @base-width;
  margin: auto;
  margin-top: 10px;
  text-align: center;
}
.footer-logo {
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-logo-img {
  width: 60px;
  height: 80px;
}
.footer-logo-dec {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.footer-logo-title {
  letter-spacing: 6px;
  font-size: 30px;
  font-weight: bolder;
}
.footer-logo-subTitle {
  font-size: 20px;
  letter-spacing: 1px;
}
.footer-message {
  display: flex;
}
.footer-connect,
.footer-meta {
  width: 320px;
  margin-right: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  .addr {
    width: 240px;
  }
  & > * {
    margin: 0;
  }
  h4 {
    font-size: 20px;
    margin-bottom: 15px;
  }
  p {
    font-size: 16px;
    margin-top: 10px;
  }
  span {
    margin-right: 10px;
    display: inline-block;
    width: 40px;
  }
}
.footer-meta {
  margin-right: 0;
  width: 140px;
}
.footer-ercode-img {
  width: 140px;
  height: 140px;
}
</style>
